@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
body {
  @apply m-0 p-0 bg-neutral;
}

/* Main content */
.main-content {
  @apply min-h-screen flex flex-col;
}

/* Header */
#header {
  /* stick header -  sticky top-0 z-50 backdrop-blur-lg */
  @apply p-4 text-center text-4xl text-base-100;
}

.intro-text {
  @apply m-4 text-justify text-base-100 prose-invert prose-sm md:prose-lg lg:prose-xl;
}

/* Toggle buttons */
.toggle-buttons {
  @apply flex justify-center gap-2 p-2;
}

.toggle-buttons > button {
  @apply p-2 text-base-100 rounded-md cursor-pointer backdrop-brightness-125 backdrop-blur-lg text-2xl lg:text-3xl;
}

/* Layout */
.bento-container {
  @apply p-4 md:grid grid-flow-row gap-4 grid-cols-3;
}

.with-scrollbar {
  /* https://adoxography.github.io/tailwind-scrollbar/examples */
  overflow-x: scroll;
  @apply overflow-x-scroll px-4 scrollbar;
}

/* General card */
.bento-card {
  @apply row-span-1 overflow-clip rounded-2xl shadow-xl;
  @apply mb-4 md:mb-0;
}

.bento-card:nth-child(even), 
.timeline:nth-child(odd) > li > .timeline-box, 
.exhibition-arts-container:nth-child(odd) > .exhibition-art,
.bento-card:nth-child(odd) .card-title,
.bento-card:nth-child(even) tr,
.bento-card:nth-child(odd) th
{
  @apply bg-accent text-base-100 border-base-100;
}

.bento-card:nth-child(odd) th {
  @apply rounded-t-lg border-none;
}

.bento-card:nth-child(odd), 
.timeline:nth-child(even) > li > .timeline-box, 
.exhibition-arts-container:nth-child(even) > .exhibition-art,
.bento-card:nth-child(even) .card-title,
.bento-card:nth-child(odd) tr,
.bento-card:nth-child(even) th,
.modal-box
{
  @apply bg-base-100 text-accent border-accent;
}

.bento-card:nth-child(even) th {
  @apply rounded-t-lg border-none;
}

.card-title {
  @apply mb-4 p-2 text-sm font-bold text-center justify-center bg-base-100 rounded-lg text-accent uppercase;
}

.bento-card:nth-child(odd) .with-scrollbar {
  @apply scrollbar-thumb-accent scrollbar-track-base-100;
}

.bento-card:nth-child(even) .with-scrollbar {
  @apply scrollbar-track-accent scrollbar-thumb-base-100;
}

.card-footer {
  @apply pl-4 pb-4;
}

/* Specific cards */

/* Event info */

.event-info > .with-padding, .counter-card {
  @apply p-4;
}

.event-date,
.event-description {
  @apply mb-4;
}

.artist-minor {
  @apply text-sm text-secondary font-normal;
}

.artist-name {
  @apply font-bold cursor-pointer underline decoration-2;
}

.artist-name:hover {
  @apply animate-pulse decoration-wavy;
}

.artist-name-no-link {
  @apply font-bold;
}

.minor-text {
  @apply text-xs font-normal opacity-50;
}

.minor-text:hover {
  @apply opacity-100;
}

.social-media-links {
  @apply flex flex-wrap gap-4 justify-around py-2 rounded-md text-accent;
}

.social-media-links > span {
  @apply bg-base-100 p-1 rounded-md;
}

.social-media-links > span > a {
  @apply cursor-pointer hover:text-secondary underline;
}

.x-button {
  @apply btn-primary btn-active rounded-lg absolute -right-1 -top-1 text-base-100;
}

/* Exhibition */

.exhibition-arts-container {
  @apply grid grid-cols-2 gap-4 p-4 lg:grid-cols-4;
}

.exhibition-art {
  @apply p-2 rounded-md flex;
}

.exhibition-art span {
  @apply text-sm;
}

/* Calendar */

.calendar {
  @apply pt-4;
}

.calendar > .card-title {
  @apply mx-4;
}

#calendar-table-container {
  @apply flex flex-row flex-nowrap items-start;
}

.calendar table {
  @apply m-1 w-full border-collapse table;
}

.calendar th,
.calendar td {
  @apply text-center p-3;
}

.calendar tr {
  @apply border-b;
}

.calendar tr:last-child {
  @apply border-none;
}

.calendar tr:hover {
  @apply shadow-2xl animate-pulse;
}

.event-list {
  @apply list-none p-0 m-0;
}

.event-list li {
  @apply mb-1;
}

.calendar-layout {
  @apply text-base-100 md:m-8;
}

.calendar-layout .card-title,
.calendar-layout .card-footer {
  @apply hidden;
}

.calendar-layout #calendar-table-container {
  @apply flex flex-col flex-nowrap items-start overflow-x-visible;
  @apply lg:grid grid-cols-2 gap-4;
}

.calendar-layout td {
  @apply border-none
}

.calendar-layout th {
  @apply mb-4 p-2 text-center justify-center bg-base-100 rounded-lg text-accent border-none;
}

/* Counter */

#counter {
  @apply grid grid-flow-col justify-center gap-5 text-center auto-cols-max p-4 lg:p-4 h-full lg:py-20;
}

/* Map */

#map {
  @apply m-0 p-0 w-full h-full min-h-72 min-w-72;
}

.leaflet-tile-container {
  /* used with https://tailwind-color-filter-generator.imbios.dev/ */
  filter: invert(90%) sepia(40%) saturate(100%) hue-rotate(94deg) brightness(90%) contrast(120%);
}

/* Story */
.see-more {
  @apply flex flex-col text-base-100 p-4 w-full h-full flex items-center justify-center backdrop-brightness-50 backdrop-contrast-75 backdrop-blur-lg justify-around;
}

/* Carousel */

.navigation-wrapper {
  @apply flex h-full w-full relative;
}

.lazy__slide {
  @apply bg-base-100 ease-in-out flex justify-center;
}

.lazy__slide img {
  @apply md:w-auto min-h-[360px] max-h-[1024px] ease-in-out;
  @apply object-cover;
}

.arrows {
  @apply absolute z-10 flex w-full h-full justify-between px-2 items-center;
}

.arrow {
  @apply cursor-pointer fill-base-100 w-7 h-fit;
  @apply backdrop-blur-lg rounded-full backdrop-brightness-50 p-2;
}

/* Footer */
#footer {
  @apply p-8 text-center text-base-100;
}

#footer a {
  @apply text-accent;
}
